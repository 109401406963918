import './App.css';
import Mailer from "./components/mailer";

function App() {
  return (
    <div className="App">
     <Mailer />
    </div>
  );
}

export default App;
